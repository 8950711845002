<template>
  <div>
    <div id="compoundPop" v-show="compoundPopShow">
      <img
        class="closeIcon"
        style="pointer-events: auto"
        @click="closeHandle"
        src="../../../imgs/compound/close.png"
      />
      <div>
        <img
          class="title"
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656332787669.png"
        />
      </div>

      <div class="compound">
        <div :class="open ? 'reduceActive' : ''">
          <div class="zh-circle" :class="flag ? 'trunActive' : ''">
            <div
              v-for="(item, index) in detail.items"
              :key="index"
              :style="{ transform: 'rotate(' + (360 / detail.items.length) * index + 'deg)' }"
            >
              <div style="position: relative">
                <img :src="item.fodder_img" alt="" srcset="" />
                <div class="mergeNum">
                  {{ item.number }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <img class="trun" src="../../../imgs/compound/trunAround.png" alt="" srcset="" />
          </div>
        </div>

        <div class="flyGif">
          <img class="trun" src="../../../imgs/compound/fly.gif" alt="" srcset="" />
        </div>

        <div class="card" :class="open ? 'cardActive' : ''">
          <div style="position: relative">
            <img src="https://activity-resource.321mh.com/nft/hecheng/zi_bg.png" alt="" srcset="" />
            <div class="cardName">{{ detail.name }}</div>
          </div>
        </div>
      </div>

      <div class="btnBox" @click="compoundHandle">
        <img src="../../../imgs/compound/btnBg.png" />
        <div class="confirmBtn">确定合成</div>
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="show" @close="$emit('close')">
      <div v-if="popType == 1">
        <div class="successImg">
          <img
            src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656397513596.png"
          />
        </div>
        <div class="bigCard" :class="show ? 'bigCardActive' : ''">
          <!-- 藏品头标题名 -->
          <div class="titleBox">
            <div style="position: relative">
              <img src="../../../imgs/compound/title_bg.png" alt="" srcset="" />
              <div class="titleName">{{ detail.name }}</div>
            </div>
          </div>
          <!-- 背景图 -->
          <img class="bg" src="https://activity-resource.321mh.com/nft/hecheng/collection_bg.png" />
          <!-- 编号 -->
          <div class="num" v-if="cardInfo.serial_num && cardInfo.serial_num > 0">
            编号：{{ cardInfo.token_id + '-' + cardInfo.serial_num }}
          </div>
          <!-- 藏品图 -->
          <div class="cardPicBox">
            <div class="cardPic">
              <img class="pic" :src="cardInfo.image_icon" alt="" srcset="" />
              <img class="levelIcon" :src="levelsChange(cardInfo.levels)" alt="" />
            </div>
          </div>
          <!-- 底部文字 -->
          <div class="footerText">{{ cardInfo.sub_name }}</div>
        </div>
        <div class="btnBox" @click="toCollections">
          <img src="../../../imgs/compound/btnBg.png" />
          <div class="confirmBtn">查看藏品</div>
        </div>
      </div>

      <div v-else class="pointPop">
        <div class="topText">恭喜获得</div>
        <div class="img">
          <img :src="cardData.img_icon" alt="" srcset="" />
        </div>
        <div class="number">
          {{ cardData.name }} x
          <span style="color: #3cdbe8; font-weight: bold">{{
            popType == 2 ? cardData.number / 100 + '元' : cardData.number
          }}</span>
        </div>
        <div class="getBtn" v-if="popType == 3" @click="show = false">领取</div>
        <div class="getBtn" v-if="popType == 2" @click="toHb">去领取</div>
        <!-- <img class="closePoint" @click="show = false" src="../../../imgs/icon/closeIcon.png" /> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue';
import { commonmergeitem } from '@/service/compound';
import { useRouter } from 'vue-router';

export default {
  props: {
    compoundPopShow: {
      type: Boolean,
      default: false
    },

    detail: {
      type: Object,
      default: () => {}
    },

    config_id: {
      type: Number,
      default: null
    }
  },

  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      step: 1,
      show: false,
      flag: false, //开始合成（请求接口，开始加载第一个动画trunActive）
      open: false, //请求接口获取合成的图片过程（加载第二个动画）reduceActive/cardActive
      popShow: true,
      cardInfo: {},
      popType: 1,
      cardData: {},

      compoundFlag: true
    });

    // 确认合成
    const compoundHandle = () => {
      if (state.compoundFlag) {
        state.flag = true;
        state.compoundFlag = false;

        // setTimeout(() => {
        //   state.open = true;
        // }, 3000);
        // return;
        setTimeout(() => {
          commonmergeitem({
            config_id: props.config_id
          }).then(res => {
            console.log(res);
            if (res.status == 0) {
              // 2是红包
              state.popType = res.data.type;
              if (state.popType == 2 || state.popType == 3) {
                state.cardData = res.data;
              } else {
                state.cardInfo = res.data;
              }

              state.open = true;
              state.show = true;

              setTimeout(() => {
                console.log(props);
                state.popShow = false;
                ctx.emit('close');
                state.open = false;
                // state.show = false;
                state.flag = false;
                state.compoundFlag = true;
              }, 1000);
            } else {
              state.compoundFlag = true;
              state.flag = false;
              // ctx.emit('close');
              toastFn({ message: res.message, duration: 3000 });
            }
          });
        }, 1000);
      } else {
        toastFn({ message: '正在合成' });
      }
    };

    // 查看藏品
    const toCollections = () => {
      // router.push({ path: '/collections' });
      router.push({
        path: '/collectionsDetail',
        query: {
          id: state.cardInfo.user_collection_id
        }
      });
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../../../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../../../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../../../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../../../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../../../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../../../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const closeHandle = () => {
      if (state.compoundFlag) {
        ctx.emit('close');
      } else {
        toastFn({ message: '正在合成' });
      }
    };

    const toHb = () => {
      router.push({ path: '/myHb' });
    };

    return {
      ...toRefs(state),
      compoundHandle,
      levelsChange,
      toCollections,
      closeHandle,
      toHb
    };
  }
};
</script>

<style lang="scss" scoped>
// 自定义字体
.fongt {
  font-family: 'df';
}
.zh-circle {
  width: 280px;
  height: 280px;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  // border: 1px solid #f00;
  box-sizing: border-box;
}
.zh-circle > div {
  font-size: 0;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 0;
  width: 70px;
  height: 70px;
  margin: -35px 0 0 -35px;
  // border: 1px solid #f00;
  border-radius: 50%;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 35px 175px;

  img {
    width: 70px;
    height: 70px;
  }
}
#compoundPop {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #202232;
  // height: 100%;
  text-align: center;
}

.pointPop {
  position: relative;
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  padding: 16px 0 20px 0;

  .closePoint {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }

  .topText {
    font-size: 18px;
    padding: 0 0 20px 0;
    margin: 0;
  }

  .img {
    font-size: 0;
    img {
      width: 48px;
    }
  }

  .number {
    font-size: 16px;
    margin: 9px 0 35px 0;
  }

  .getBtn {
    font-size: 16px;
    margin: 0 auto;
    width: 166px;
    height: 44px;
    line-height: 44px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
}
.closeIcon {
  position: absolute;
  z-index: 5000;
  width: 16px;
  height: 16px;
  padding: 20px;
  right: 0;
  top: 0;
}
.title {
  // width: 100%;
  width: 269px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.compound {
  width: 100%;
  position: relative;
  // height: 388px;

  .mergeNum {
    position: absolute;
    text-align: center;
    border-radius: 50%;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    background: #2f293f;
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }

  .flyGif {
    width: 100%;
    // height: 388px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;

    img {
      width: 100%;
    }

    .cardName {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      font-size: 16px;
      font-family: 'df';
      font-weight: normal;
      color: #202232;
      writing-mode: tb-rl;
    }
  }

  .trunAround {
    position: absolute;
    left: 0;
    top: 0;
  }

  .trun {
    width: 100%;
  }

  .trunIcon {
    position: absolute;
    left: 0;
    top: 0;
  }

  .positionNum {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 18px;
    left: 53%;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #2f293f;
  }

  .trunActive {
    animation: trun 2s linear infinite;
  }

  .reduceActive {
    animation: reduce 1s linear 1;
  }

  .cardActive {
    animation: big 1s linear 1;
  }
}

.btnBox {
  width: 164px;
  font-size: 0;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;

  img {
    width: 100%;
  }

  .confirmBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    color: #20322c;
  }
}

.successImg {
  width: 100%;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}

.bigCard {
  text-align: center;
  width: 292px;
  position: relative;

  .titleBox {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 236px;
    }

    .titleName {
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      font-family: 'df';
      font-size: 20px;
      font-weight: normal;
      background: linear-gradient(180deg, #ffffff 0%, #ffeccd 45%, #a78b5f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .footerText {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    font-family: 'df';
    font-weight: normal;
    background: linear-gradient(180deg, #ffc466 0%, #ffeccd 45%, #a78b5f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg {
    width: 100%;
  }

  .num {
    position: absolute;
    width: 100%;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .cardPicBox {
    position: absolute;
    width: 234px;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);

    .cardPic {
      position: relative;

      .pic {
        width: 100%;
      }

      .levelIcon {
        position: absolute;
        width: 60px;
        bottom: 21px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.bigCardActive {
  animation: bigCardAnimate 0.5s linear 1;
}

@keyframes trun {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes reduce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
  }
}

@keyframes big {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

@keyframes bigCardAnimate {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
