<template>
  <!-- 基础合成通用页面 -->
  <div style="overflow-x: hidden">
    <div class="container">
      <div class="head-bg">
        <van-image width="100vw" :src="detail.merge_image_bg" />
        <div class="meng"></div>
      </div>

      <div class="content">
        <div class="content-item contentFirst">
          <div class="title">
            <div class="name">
              {{ detail.name }}
              <img :src="levelsChange(detail.levels)" alt="" />
            </div>
            <div class="num">限量合成{{ detail.number }}份</div>
          </div>
          <div class="cl-box">
            <div class="title"><img src="./img/cailiao1.png" alt="" /></div>
            <div class="hero" v-if="detail.items && detail.items.length">
              <div class="hero-item" v-for="(item, index) in detail.items" :key="index">
                <div class="box1">
                  <img :src="item.fodder_img" alt="" />
                  <div class="num">{{ item.user_num }}</div>
                  <div class="item-meng" @click="heroFn" v-if="item.user_num < item.number"></div>
                  <div class="neednum">
                    需<span>{{ item.number }}</span
                    >个
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-wrapper">
            <cpdBtn
              :time="detail.start_time"
              :end_time="detail.end_time"
              :hasAll="detail.hasAll"
              @finistTime="finistTime"
              :is_merge="detail.is_merge"
              @compoundHreo="compoundHreo"
              :server_time="server_time"
              :merge_type="detail.merge_type"
            />
          </div>

          <!-- <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>传说级藏品</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div> -->
        </div>
        <!-- 详情图 -->
        <div class="detailImgContianer" v-if="detail.image">
          <img v-for="(item, index) in JSON.parse(detail.image)" :src="item" :key="index" alt="" />
        </div>
        <div class="brigt">
          <div class="">
            <div class="t-c">
              <img class="wid237" :src="require('@/imgs/comm/Slice15hc.png')" alt="" />
            </div>
            <div class="t-c f-10 brigt-content c-07">限量合成{{ detail.number }}份</div>

            <div class="">
              <div class="flex-bt marg36">
                <img class="wid90" :src="require('@/imgs/comm/Slice20.png')" alt="" />
                <img class="wid90" :src="require('@/imgs/comm/Slice21.png')" alt="" />
              </div>
              <div class="flex-bt">
                <img class="wid90" :src="require('@/imgs/comm/Slice22.png')" alt="" />
                <img class="wid90" :src="require('@/imgs/comm/Slice23.png')" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="parameter f-12">
          <div class="">
            <div class="title t-c">
              <img class="wid237" :src="require('@/imgs/comm/Slice16.png')" alt="" />
            </div>
            <!-- <div class="bright-item">
            <span class="c-07">认证标识</span>
            <span> 已上链</span>
          </div>
          <div class="bright-item">
            <span class="c-07">认证标准</span>
            <span> ERC1155</span>
          </div>
          <div class="bright-item">
            <span class="c-07">认证网络</span>
            <span> {{ productDetail.token_network }}</span>
          </div> -->
            <div class="bright-item">
              <span class="c-07">商品名称</span>
              <span> {{ productDetail.items_name }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品类型</span>
              <span> {{ productDetail.type_name }}</span>
            </div>

            <div class="bright-item">
              <span class="c-07">品牌方</span>
              <span> {{ productDetail.producter }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">发行方</span>
              <span> {{ productDetail.publisher }}</span>
            </div>
            <div class="bright-item" v-if="productDetail.publish_date">
              <span class="c-07">发行时间</span>
              <span> {{ timeChange(productDetail.publish_date, 'YYYY年MM月DD日') }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品编号</span>
              <span> {{ productDetail.series_num }}</span>
            </div>
            <!-- <div class="bright-item">
            <span class="c-07">铸造数量</span>
            <span> {{ productDetail.token_num }}</span>
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <PageLoading :show="loading" />
    <compoundPop
      :compoundPopShow="compoundPopShow"
      :detail="detail"
      :config_id="config_id"
      @close="(compoundPopShow = false), finistTime()"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { commonstuff } from '@/service/compound';
import cpdBtn from '@/components/CpdBtn';
import compoundPop from './components/basicCompoundPop.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { Toast } from 'vant';
import { shareHelper } from '@/helpers';
import { domUtil } from '@/utils';
import { produceDetail } from '@/service/home';
import { useRoute } from 'vue-router';

export default {
  components: {
    cpdBtn,
    compoundPop,
    PageLoading
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      detail: {},
      compoundPopShow: false,
      token_id: 0, // 选择的材料中的id
      loading: true,
      server_time: 0,
      productDetail: {},
      config_id: route.query.config_id || null
    });

    onMounted(async () => {
      finistTime();
      const shareConf = {
        title: '看漫数藏',
        content: '由看漫APP倾力打造，藏品有限，价值无限',
        image: '',
        link: window.location.origin + '/home', // app分享链接
        url: window.location.origin + '/home' // app分享链接
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);

      console.log('state.detail..', state.detail);
    });
    const heroFn = async () => {
      Toast('该材料不足，快去购买材料吧～');
    };
    const finistTime = async () => {
      const { data, server_time } = await commonstuff({ config_id: state.config_id });
      state.detail = data;
      state.server_time = server_time;

      let merge_config_items = data.items,
        user_merge_config_items = data.user_stuff_list,
        arr = [],
        subitem,
        hasAll = true; // 材料是否充足;
      // 循环得出用户拥有数

      merge_config_items.map((item, index) => {
        if (user_merge_config_items.length == 0) {
          hasAll = false;
          console.log(11111);
          arr.push({ ...item, user_num: 0 });
        } else {
          for (let i = 0; i < user_merge_config_items.length; i++) {
            subitem = user_merge_config_items[i];

            if (item.token_id == subitem.token_id) {
              arr.push({ ...item, user_num: subitem.number });
              // 判断材料2 选择第一个
              // 材料1不足
              if (subitem.number < item.number) {
                hasAll = false;
              }

              break;
            }
            if (user_merge_config_items.length - 1 == i && item.token_id != subitem.token_id) {
              arr.push({ ...item, user_num: 0 });
              // 材料不足
              hasAll = false;

              break;
            }
          }
        }
      });

      state.detail.items = arr;
      state.detail = { ...state.detail, hasAll };

      console.log('state.detail', state.detail);
      state.loading = false;

      state.productDetail = data.items_result;

      // produceDetail({ collections_id: state.detail.collections_id }).then(res => {
      //   if (res.status == 0) {
      //     res.data.length && (state.productDetail = res.data[0]);
      //   }
      // });
    };
    // 合成按钮
    const compoundHreo = () => {
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const userNum = id => {
      return 1;
    };
    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      userNum,
      finistTime,
      compoundHreo,
      timeChange,
      heroFn,
      diffTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.container {
  position: relative;

  .head-bg {
    position: relative;

    .meng {
      width: 100vw;
      height: 160px;
      position: absolute;
      // top: -160px;
      bottom: 0;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
    }
  }

  .content {
    padding: 0 16px 10px;
    position: absolute;
    top: 100%;
    margin-top: -80px;
    padding-bottom: 120px;

    .content-item {
      background: #2f293f;
      padding: 34px 11px 10px;
      margin-bottom: 36px;
      position: relative;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        .name {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
        }
        img {
          width: 50px;
          margin-left: 5px;
        }
        .num {
          background: #3cdbe8;
          color: #2f293f;
          padding: 0 8px;
          line-height: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
        }
      }
      .cl-box {
        background: #1c172a;
        padding: 0 5px 10px 5px;
        margin-top: 18px;
        .title {
          text-align: center;
          font-size: 0px;
          padding: 5px 0;
          img {
            width: 237px;

            margin: 0 auto;
          }
        }
        .hero-wrapper {
          display: flex;
          justify-content: center;
        }
        .hero1 {
          padding: 0 27px;
        }
        .hero {
          display: flex;
          flex-wrap: wrap;
          font-size: 0px;
          align-items: center;
          justify-content: space-around;
          //justify-content: space-between;
          img {
            width: 70px;
          }
          .mr10 {
            margin-right: 10px;
          }
          .choose {
            border: 1px solid #5fdfd3;
            border-radius: 4px;
            background-color: #2f293f;
          }
          .nochoose {
            border: 1px solid #1c172a;
            border-radius: 4px;
          }
          // .w33 {
          //   width: 33%;
          // }
          .hero-item {
            width: 25%;
            // position: relative;

            .box1 {
              // margin-right: 10px;
              // &:nth-child(3n + 4) {
              //   margin-right: 0px;
              // }
              position: relative;
              margin: 0 auto;
              text-align: center;
            }
            .martop14 {
              margin-top: 14px;
            }
            .num {
              position: absolute;
              right: 9px;
              top: 9px;
              background: #2f293f;
              font-size: 12px;
              width: 18px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              z-index: 2;
            }
            .neednum {
              font-size: 12px;
              text-align: center;
              span {
                color: $primary;
              }
            }
            .item-meng {
              width: 69px;
              height: 73px;
              background: #2f293f;
              opacity: 0.5;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0);
              border-radius: 50%;
            }
          }
        }
      }
    }

    .contentFirst {
      margin-top: 0;
      padding-top: 16px;
    }

    .detailImgContianer {
      font-size: 0;
      margin-bottom: 26px;
      img {
        width: 100%;
      }
    }
  }
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../../imgs/comm/Slice9.png');
  margin-bottom: 24px;
  .brigt-content {
    margin-bottom: 24px;
  }
}

.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}

.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
</style>
  